<template>
  <div class="content">
    <!-- 导航栏 -->
    <el-header class="zg--header--main">
      <img
        class="zg--header--logo"
        src="https://image.qll-times.com/2021/04/e08a661764ea49c6a03d5278aa74283e.png"
      />
      <label class="zg--header--title">未申请企业</label>
    </el-header>

    <!-- 搜索框 -->
      <div class="header-item">
        <el-input v-model="params.name"  placeholder="请输入企业名称" clearable @clear="getList(1)" suffix-icon="el-icon-search">
        </el-input>
      </div>
       
    
      <div class="header-item">
        <el-button type="primary" @click="getList(1)" icon="el-icon-search">搜索</el-button>
      </div>

      
      <!-- 未申请企业列表及操作 -->
      <div >
        <el-table ref="multipleTable" tooltip-effect="dark" style="width: 100%; margin-top: 10px" 
          header-row-class-name="table-header-style"
          :data="dataList"
           @selection-change="selectHandle"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
          <el-table-column label="公司名称">
            <template slot-scope="scope">
               {{scope.row.name || '无'}}
            </template>
          </el-table-column>

           <el-table-column label="主要负责人"  >  
            <template slot-scope="scope">
               {{scope.row.applicant || '无'}}
            </template>
          </el-table-column>


          <el-table-column label="使用人数" width="100px" align="center" >
            <template slot-scope="scope">
               {{scope.row.member_limit || '无'}}
            </template>
          </el-table-column>
         
           <el-table-column label="注册时间"  align="center" >
            <template slot-scope="scope">
               {{scope.row.expire_at | dateFormat}}
            </template>
          </el-table-column>
           <el-table-column label="联系电话" width="80px" align="center" >
            <template slot-scope="scope">
               {{scope.row.tel || '无'}}
            </template>
          </el-table-column>
         
          <!-- 查看内容，编辑，删除 -->
          <!-- <el-table-column label="操作" width="190">
            <template slot-scope="scope">
              <el-button  type="text" @click="pass(scope.row)">查看内容 </el-button>
              <el-button  type="text" @click="pass(scope.row)">编辑</el-button>
              <el-button  type="text" @click="refuse(scope.row)" style="color: #ff0000">删除</el-button>     
            </template>
          </el-table-column> -->
        </el-table>
        <!-- 分页 -->
        <div style="margin-top: 20px; overflow: hidden">
           <!-- <el-button :disabled="!selectList" >批量删除</el-button> -->
          <div style="float: right">
            <el-pagination @current-change="getList" layout="total, prev, pager, next, jumper"
              :background="true"
              :current-page="params.pageIndex"
              :page-size="params.pageNumber"
              :total="total">
            </el-pagination>
          </div>
        </div>

      </div>
  </div>
</template>
<script>
  export default {
  data(){
    return {
      params:{
        name:'',
        pageNumber:6,
        pageIndex:0
      },
      dataList:[],
      total:0,
      selectList:'',//当前选中的所以对象id

    }
  },
  created() {
    this.getList(1)
  },
  methods: {
    //获取列表
    async getList(page) {
      this.params.pageIndex=page;
      if(this.params.name =='') delete this.params.name;
      console.log(this.params);
      let res = await this.$ZGManager.companysApp(this.params)
      if (res.status !== 200)return;
      this.dataList = res.data.list;
      this.total = res.data.total_rows;
    },
    //选中之后
    selectHandle(val) {
      this.selectList = ''
      val.map(e=> {
        this.selectList += `${e.id},`
      });
      this.selectList = this.selectList.substring(0,this.selectList.length-1);
      // 
    },
  }
}
</script>
<style lang="scss" scoped>
.content {
  padding: 10px;
  background-color: #fff;
  margin-bottom: 50px;
  font-size: 14px;
 
  .header-item{
  display: inline-block;
  // margin-right: 10px;
  margin: 10px;
  // margin-bottom: 10px;
  vertical-align: middle;
  }
}
 .header {
    padding: 20px;
    i {
      padding: 0 5px;
    }
 }
</style>